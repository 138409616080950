import React, { memo } from 'react';
import CustomChart from '../components/CustomChart/CustomChart';

const options = {
  maintainAspectRatio: false,
  responsive: true,
};

const normalizedData = ({ labels, datasets }) => {
  const result = {
    labels,
    datasets: [
      {
        data: datasets?.[0]?.data ?? [],
        backgroundColor: ['#2979ff', '#ffe367', '#f7a6a6', '#d9d9d9'],
      },
    ],
  };

  return result;
};
const CustomerLocale = (props) => {
  return (
    <CustomChart
      type={'pie'}
      {...props}
      normalizedData={normalizedData}
      getOption={() => options}
    />
  );
};

export default memo(CustomerLocale);
